@use 'src/src/app/scss/abstracts' as *;

.highlights-carousel {
  //   &#{$block-name} {
  //   }

  $block-name: &;

  overflow: hidden;

  &__inner-wrap {
    position: relative;
    margin-left: -3px;
    margin-right: -3px;
    z-index: 0;
    padding-top: 0;
    padding-bottom: 40px;
    overflow: hidden;

    @include mq(md) {
      margin-left: -13px;
      margin-right: -13px;
    }

    @include mq(lg) {
      margin-left: -7.5px;
      margin-right: -7.5px;
    }
  }

  &__inner,
  swiper-container {
    display: flex !important;
    flex-wrap: nowrap !important;
  }

  &__cell,
  swiper-slide {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto !important;
    flex-direction: column;
    flex-grow: 1;
    width: 100% !important;
    padding-left: 3px;
    padding-right: 3px;

    @include mq(sm) {
      width: 50% !important;
    }

    @include mq(md) {
      padding-left: 7.5px;
      padding-right: 7.5px;
    }

    @include mq(lg) {
      padding-left: 13px;
      padding-right: 13px;
      width: 33.333% !important;
    }
  }
}
